import {Select} from 'antd'
import {FC, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {QueryState} from '../../../../../../_metronic/helpers'
import compareLabelMatch from '../../../../../../untils/compare-label-match'
import {City} from '../../../../../modules/auth'
import {useAuth} from '../../../../../modules/auth/core/Auth'
import {District, Ward} from '../../../../MAP_New2/core/_models'
import {getProvinces} from '../../../../MAP_New2/core/_requests'
import {
  getDistrictByCityId,
  getWardByDistrictId,
} from '../../../../diachi/diachi-list/core/_requests'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {DeviceInfoListSearchComponent} from './DeviceInfoListSearchComponent'

type Props = {
  changeTab: any
}
const ThietBiListHeader: FC<Props> = ({changeTab}) => {
  const {deviceType, auth, currentUser, lstImei, citySelectedAuth} = useAuth()
  const {state, updateState} = useQueryRequest()
  const [lstCity, setLstCity] = useState<Array<City>>([])
  const [cityIdSelected, setCityIdSelected] = useState<string>('')
  const [lstDistrict, setLstDistrict] = useState<Array<District>>([])
  const [lstWard, setLstWard] = useState<Array<Ward>>([])
  const [districtIdSelected, setDistrictIdSelected] = useState<string>('')
  const [wardIdSelected, setWardIdSelected] = useState<string>('')
  const initialQueryState: QueryState = {
    currentPage: state.currentPage,
    pageSize: state.pageSize,
    totalPages: 1,
    totalItems: 0,
  }
  const {state: stateDevice}: any = useLocation()

  useEffect(() => {
    getProvinces(auth?.configs).then((data) => {
      setLstCity([{CityName: 'Tất cả', CityId: ' '}, ...data])
      if (data.length > 0) {
        let dId = stateDevice
          ? stateDevice?.provinceId
          : (
              localStorage.getItem('diachi') &&
              JSON.parse(localStorage.getItem('diachi') ?? '')?.tinh
            )?.trim() || data[0]?.CityId
        if (citySelectedAuth) {
          setCityIdSelected(citySelectedAuth.CityId || '')
          dId =
            (localStorage.getItem('diachi') &&
              JSON.parse(localStorage.getItem('diachi') ?? '')?.tinh) ||
            citySelectedAuth.CityId
        } else
          setCityIdSelected(
            stateDevice
              ? stateDevice?.provinceId
              : (localStorage.getItem('diachi') &&
                  JSON.parse(localStorage.getItem('diachi') ?? '')?.tinh) ||
                  data[0]?.CityId
          )
        getDistrictByCityId(auth?.configs, dId || '').then((res) => {
          setLstDistrict(res)
          if (
            localStorage.getItem('diachi') &&
            JSON.parse(localStorage.getItem('diachi') ?? '')?.huyen
          ) {
            setDistrictIdSelected(JSON.parse(localStorage.getItem('diachi') ?? '').huyen)
            getWardByDistrictId(
              auth?.configs,
              JSON.parse(localStorage.getItem('diachi') ?? '')?.tinh,
              JSON.parse(localStorage.getItem('diachi') ?? '')?.huyen
            ).then((res) => {
              setLstWard(res)
              setWardIdSelected(JSON.parse(localStorage.getItem('diachi') ?? '')?.xa || '')
            })
          }
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateDevice])

  const changeCity = (cId: string) => {
    setCityIdSelected(cId)
    setLstDistrict([])
    setLstWard([])
    getDistrictByCityId(auth?.configs, cId).then((res) => {
      setLstDistrict(res)
    })
    setDistrictIdSelected('')
    setWardIdSelected('')
  }

  const changeDistrict = (dId: string) => {
    if (dId) {
      setDistrictIdSelected(dId)
      setLstWard([])
      getWardByDistrictId(auth?.configs, cityIdSelected || '', dId || '').then((res) => {
        setLstWard(res)
      })
      setWardIdSelected('')
    } else {
      setWardIdSelected('')
      setDistrictIdSelected('')
      setLstWard([])
    }
  }

  useEffect(() => {
    if (cityIdSelected.length > 0) {
      let tmp: any = {
        ...((state.filter as object) || {
          Location: {
            CityId: cityIdSelected,
            DistrictId: '',
          },
          Info: '',
          Imei: '',
          ManufactureName: '',
          Address: '',
          DeviceGroupName: '',
          Firmware: '',
          Volumne: '',
          GsmInfo: '',
          State: null,
          GroupIds: currentUser?.DeviceGroupIds,
          ListImei: lstImei,
          FromTime: null,
          ToTime: null,
        }),
      }
      tmp['State'] = deviceType

      tmp['Location'] = {
        CityId: cityIdSelected,
        DistrictId: districtIdSelected ?? '',
        WardId: wardIdSelected ?? '',
      }
      updateState({
        search: Math.random().toString(),
        filter: tmp,
        ...initialQueryState,
      })
    }

    setTimeout(() => {
      if (cityIdSelected || districtIdSelected || wardIdSelected) {
        localStorage.setItem(
          'diachi',
          JSON.stringify({tinh: cityIdSelected, huyen: districtIdSelected, xa: wardIdSelected})
        )
      }
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceType, cityIdSelected, districtIdSelected, wardIdSelected])

  return (
    <div className='card-header border-0 pt-6 nguoi-dung-body'>
      {/* <UsersListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-title'>
        {/* begin::Search */}
        <div className='div-select-city'>
          <label className='fw-bold fs-6 mb-2'>Tỉnh:</label>
          <Select
            style={{width: '180px'}}
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            data-kt-user-table-filter='two-step'
            data-hide-search='true'
            value={cityIdSelected}
            onChange={(value) => changeCity(value)}
            options={lstCity.map((item) => ({label: item.CityName, value: item.CityId}))}
            showSearch
            filterOption={compareLabelMatch}
          />
        </div>
        <div className='div-select-city'>
          <label className='fw-bold fs-6 mb-2'>Huyện:</label>
          <Select
            style={{width: '180px'}}
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            data-kt-user-table-filter='two-step'
            data-hide-search='true'
            value={districtIdSelected}
            onChange={(value) => changeDistrict(value)}
            options={[
              {
                label: 'Tất cả',
                value: '',
              },
              ...lstDistrict?.map((item) => ({
                label: item.DistrictName,
                value: item.DistrictId,
              })),
            ]}
            showSearch
            filterOption={compareLabelMatch}
          />
        </div>
        <div className='div-select-city'>
          <label className='fw-bold fs-6 mb-2'>Xã:</label>
          <Select
            style={{width: '180px'}}
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            data-kt-user-table-filter='two-step'
            data-hide-search='true'
            value={wardIdSelected}
            onChange={(value) => setWardIdSelected(value)}
            options={[
              {
                label: 'Tất cả',
                value: '',
              },
              ...lstWard?.map((item) => ({
                label: item.WardName,
                value: item.WardId,
              })),
            ]}
            showSearch
            filterOption={compareLabelMatch}
          />
        </div>
        {/* end::Search */}
      </div>
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        <DeviceInfoListSearchComponent changeTab={changeTab} />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ThietBiListHeader}
