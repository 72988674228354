/* eslint-disable react-hooks/exhaustive-deps */
import {CheckCircleOutlined, DownloadOutlined, PlusOutlined} from '@ant-design/icons'
import {FC, useState} from 'react'
import {useMutation} from 'react-query'
import {Loading} from '../../../../../../_metronic/layout/components/loading/Loading'
import {BtnRefetch} from '../../../../../../components'
import {useAuth} from '../../../../../modules/auth/core/Auth'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse, useQueryResponseData} from '../../core/QueryResponseProvider'
import {ExportDeviceInfor2} from '../../core/_requests'
import DeviceModal from './DeviceModal'
import ThietBiNCCModal from './ThietBiNCCModal'

type Props = {
  changeTab: any
}
const DeviceInfoListSearchComponent: FC<Props> = ({changeTab}) => {
  const {auth, addressD, setAddressD} = useAuth()
  const data = useQueryResponseData()
  const {refetch} = useQueryResponse()
  const {state, updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>(addressD || '')
  const [isLoading, setIsLoading] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [isModalApprovalOpen, setIsModalApprovalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const showModalApproval = () => {
    setIsModalApprovalOpen(true)
  }

  const handleCancel = () => {
    setIsModalApprovalOpen(false)
  }

  const Export = () => {
    setIsLoading(true)
    exportDevice2.mutateAsync()
  }

  const exportDevice2 = useMutation(() => ExportDeviceInfor2(auth?.configs, state), {
    onSuccess: (response) => {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Danhsachthietbi.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setIsLoading(false)
    },
  })

  return (
    <>
      <BtnRefetch refetch={refetch} />
      <button type='button' className='btn btn-primary me-3' onClick={showModalApproval}>
        <CheckCircleOutlined /> Phê duyệt thiết bị
      </button>
      <button
        type='button'
        className='btn btn-primary me-3'
        onClick={Export}
        disabled={data.length === 0}
      >
        <DownloadOutlined />
        Xuất dữ liệu
      </button>
      <button type='button' className='btn btn-primary me-3' onClick={showModal}>
        <PlusOutlined />
        Thêm mới
      </button>
      <button
        type='button'
        className='btn btn-light-primary'
        title='Nhật ký hoạt động'
        onClick={() => changeTab(3)}
      >
        <i className='bi bi-clock-history' style={{paddingRight: 0}}></i>
      </button>
      {isLoading && <Loading />}
      <ThietBiNCCModal
        handleCancel={handleCancel}
        isModalSpeakerOpen={isModalApprovalOpen}
      ></ThietBiNCCModal>
      {isModalOpen && (
        <DeviceModal
          handleCancel={handleCancel}
          isModalOpen={isModalOpen}
          isElectronicBoard={false}
        />
      )}
    </>
  )
}

export {DeviceInfoListSearchComponent}
